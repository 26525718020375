<template>
  <main class="order-infobox">
    <table>
      <tr>
        <vs-button @click="edit()" class="mt-base" type="filled">{{ editMode ? $t("shops.cancel") : $t("shops.edit") }}</vs-button>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.id') }}</th>
        <td>{{ value.id }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.reference') }}</th>
        <td>{{ value.reference }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.firstname') }}</th>
        <td v-if="!editMode">{{ value.firstname }}</td>
        <td v-else>
          <vs-input
          class="w-full"
          v-model="clientInfo.firstname"
          name="firstname"
        />
        </td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.lastname') }}</th>
        <td v-if="!editMode">{{ value.lastname }}</td>
        <td v-else>
          <vs-input
            class="w-full"
            v-model="clientInfo.lastname"
            name="lastname"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.email') }}</th>
        <td v-if="!editMode">{{ value.email }}</td>
        <td v-else>
          <vs-input
            class="w-full"
            v-model="clientInfo.email"
            name="email"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.phone') }}</th>
        <td v-if="!editMode">{{ value.phone }}</td>
        <td v-else>
          <vs-input
            class="w-full"
            v-model="clientInfo.phone"
            name="phone"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.subtotal') }}</th>
        <td>{{ value.total_price | money(value.currency) }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.taxes') }}</th>
        <td>{{ value.total_tax | money(value.currency) }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.total_without_discounts') }}</th>
        <td>{{ value.total_products_tax | money(value.currency) }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.total_with_discounts') }}</th>
        <td>{{ value.total_price_tax | money(value.currency) }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.promotion') }}</th>
        <td>{{ value.promotion ? value.promotion.name : "---" }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.date_purchase') }}</th>
        <td>{{ value.created_at | datetime}}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.payment') }}</th>
        <td>{{ value.payment ? value.payment.method : "---" }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.transaction') }}</th>
        <td>{{ value.payment ? value.payment.transaction_code : "---" }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.external_id') }}</th>
        <td>{{ value.external_id || "---" }}</td>
      </tr>
      <tr>
        <th>{{ $t('shops.order_info.infobox.status') }}</th>
        <td v-if="!editMode">{{ value.status.name }}</td>
        <td v-else>
          <v-select
            :value="statusSelected"
            :options="status.map(status => ({label: status.label, value: status.label, id: status.value}))"
            class="w-full"
            name="status"
            :placeholder="$t('shops.select_a_status')"
            @input="status => selectStatus(status)">
          </v-select>
        </td>
      </tr>
      <tr>
        <vs-button @click="onSave()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
      </tr>
    </table>
  </main>
</template>

<script>
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { shopapi } from "@/axios.js";
import { mapActions, mapGetters} from 'vuex';
import i18n from "@/i18n";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  name: "order-infobox",

  mixins: [notifications],

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      status: [
        {label: i18n.t("shops.pending_to_pay"), value: "1"},
        {label: i18n.t("shops.booked"), value: "2"},
        {label: i18n.t("shops.payed"), value: "3"},
        {label: i18n.t("shops.confirmed"), value: "4"},
        {label: i18n.t("shops.redeemed"), value: "7"},
        {label: i18n.t("shops.error_select"), value: "5"},
        {label: i18n.t("shops.cancelled"), value: "6"}
      ],
      statusSelected: "",
      optionSelected: null,
      clientInfo: {
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
      },
      editMode: false
    };
  },
  created() {
    switch (this.value.status.id) {
      case 1:
        this.statusSelected = i18n.t("shops.pending_to_pay")
        break;
      case 2:
        this.statusSelected = i18n.t("shops.booked")
        break;
      case 3:
        this.statusSelected = i18n.t("shops.payed")
        break;
      case 4:
        this.statusSelected = i18n.t("shops.confirmed")
        break;
      case 5:
        this.statusSelected = i18n.t("shops.error_select")
        break;
      case 6:
        this.statusSelected = i18n.t("shops.cancelled")
        break;
      case 7:
        this.statusSelected = i18n.t("shops.redeemed")
        break;
      default:
        this.statusSelected = "String not found"
        break;
    }
  },
  updated() {
    Object.keys(this.clientInfo).forEach(property => {
      this.clientInfo[property] = this.value[property]
    })
  },
  computed: {
    ...mapGetters(['AppActiveUser']),
  },
  methods: {
    ...mapActions("shops/orders", {
      loadOrders: "load",
      resetResource: "resetResource",
      updateOrder: "update",
    }),

    async selectStatus(option) {
      if(option) {
        this.statusSelected = option.value
        this.optionSelected = option
      }
    },

    edit(){
      this.editMode = !this.editMode
    },

    checkModifiedInputs(){
      return Object.keys(this.clientInfo).some(property => this.clientInfo[property] !== this.value[property])
    },

    async onSave() {
      let payload = {}
      payload.status_id = this.optionSelected ? this.optionSelected.id : null;
      payload.username = this.AppActiveUser.username;
      this.loadOrder(this.value.id, payload);
    },

    async loadOrder(id, payload) {
      this.$vs.loading();
      try {
        if(this.editMode && payload.status_id)
          await shopapi.post(
            `/api/${version}/resorts/${this.$route.params.uuid}/orders/${id}/update_status`, payload
          )
        if(this.editMode && this.checkModifiedInputs()) {
          await this.updateOrder(this.clientInfo)
        }
      } catch (e) {
        console.warn(e);
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_modify_status"));
        this.$vs.loading.close();
      } finally {
        this.resetResource();
        await this.loadOrders({id: id});
        this.notifySuccess(
          i18n.t("shops.modified"),
          i18n.t("shops.order_modified_successfully")
        )
        this.$vs.loading.close();
      }
    }
  }
};
</script>

<style>
</style>
