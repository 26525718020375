<template>
  <main class="order-history">
    <data-table :data="tableData" :options="tableOptions" />
  </main>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import orderCases from "@/modules/Shops/Helpers/orderCases.js"

export default {
  name: "order-history",

  props: {
    value: { type: Array, required: true }
  },

  components: {
    DataTable
  },

  data() {
    return {};
  },

  computed: {
    tableData() {
      return this.value.map(this.orderHistoryToTableData);
    },

    tableOptions() {
      return {
        actions: false,
        filters: false,
        pagination: false
      };
    }
  },

  methods: {
    orderHistoryToTableData(orderHistory) {
      if (
        orderHistory.info !== null &&
        orderHistory.order_detail_id === null &&
        (
          orderHistory.info.includes("status ") ||
          orderHistory.info.includes("estado") ||
          orderHistory.info.includes("état") ||
          orderHistory.info.includes("estat") ||
          orderHistory.info.includes("stato")
        )
      ) {
        orderHistory.info = orderCases.getStatusName(orderHistory.info);
      } else {
        return false;
      }
      return {
        info: orderHistory.info,
        date: orderHistory.created_at
      };
    }
  }
};
</script>

<style></style>
