import i18n from '@/i18n'

export default {
  getStatusDetailName(value) {
    switch (value) {
      case "1":
        // New assigned status detail: Created
        return i18n.t("shops.order_actions.status.created");
      case "2":
        // New assigned status detail: Booked
        return i18n.t("shops.order_actions.status.booked");
      case "3":
        // New assigned status detail: Payed
        return i18n.t("shops.order_actions.status.payed");
      case "4":
        // New assigned status detail: Confirmed
        return i18n.t("shops.order_actions.status.confirmed");
      case "5":
        // New assigned status detail: Error
        return i18n.t("shops.order_actions.status.error");
      case "6":
        // New assigned status detail: Cancelled
        return i18n.t("shops.order_actions.status.cancelled");
      case "7":
        // New assigned status detail: Redeemed
        return i18n.t("shops.order_actions.status.redeemed");
      default:
        return value;
    }
  },

  getStatusName(value) {
    switch (value) {
      case "1":
        // New assigned status: Created
        return i18n.t("shops.order_history.status.created");
      case "2":
        // New assigned status: Booked
        return i18n.t("shops.order_history.status.booked");
      case "3":
        // New assigned status: Payed
        return i18n.t("shops.order_history.status.payed");
      case "4":
        // New assigned status: Confirmed
        return i18n.t("shops.order_history.status.confirmed");
      case "5":
        // New assigned status: Error
        return i18n.t("shops.order_history.status.error");
      case "6":
        // New assigned status: Cancelled
        return i18n.t("shops.order_history.status.cancelled");
      case "7":
        // New assigned status: Redeemed
        return i18n.t("shops.order_history.status.redeemed");
      default:
        return value;
    }
  },

  getActionName(value) {
    switch (value) {
      case "order_add":
        // Order created
        return i18n.t("shops.order_actions.actions.order_add");
      case "status_upd":
        // Order status updated
        return i18n.t("shops.order_actions.actions.status_upd");
      case "status_detail_upd":
        // Order detail status updated
        return i18n.t("shops.order_actions.actions.status_detail_upd");
      case "acs_error":
        // An error ocurred on Access Point
        return i18n.t("shops.order_actions.actions.acs_error");
      case "acs_book":
        // Order booked on Access Point
        return i18n.t("shops.order_actions.actions.acs_book");
      case "acs_confirm":
        // Order confirmed on Access Point
        return i18n.t("shops.order_actions.actions.acs_confirm");
      case "acs_confirm_detail":
        // Order detail confirmed on Access Point
        return i18n.t("shops.order_actions.actions.acs_confirm_detail");
      case "acs_cancel":
        // Order cancelled on Access Point
        return i18n.t("shops.order_actions.actions.acs_cancel");
      case "acs_cancel_detail":
        // Order detail cancelled on Access Point
        return i18n.t("shops.order_actions.actions.acs_cancel_detail");
      case "acs_sync_id":
        // Access point ID synchronized
        return i18n.t("shops.order_actions.actions.acs_sync_id");
      case "email_confirm_sended":
        // Confirmation email sended
        return i18n.t("shops.order_actions.actions.email_confirm_sended");
      case "email_confirm_error":
        // An error ocurred when trying to send the confirmation email
        return i18n.t("shops.order_actions.actions.email_confirm_error");
      default:
        return value;
    }
  },

  getActionNameHistory(value) {
    switch (value) {
      case "order_add":
        // Order created
        return this.$t("shops.order_history.actions.order_add");
      case "status_upd":
        // Order status updated
        return this.$t("shops.order_history.actions.status_upd");
      case "status_detail_upd":
        // Order detail status updated
        return this.$t("shops.order_history.actions.status_detail_upd");
      case "acs_error":
        // An error ocurred on Access Point
        return this.$t("shops.order_history.actions.acs_error");
      case "acs_book":
        // Order booked on Access Point
        return this.$t("shops.order_history.actions.acs_book");
      case "acs_confirm":
        // Order confirmed on Access Point
        return this.$t("shops.order_history.actions.acs_confirm");
      case "acs_confirm_detail":
        // Order detail confirmed on Access Point
        return this.$t("shops.order_history.actions.acs_confirm_detail");
      case "acs_cancel":
        // Order cancelled on Access Point
        return this.$t("shops.order_history.actions.acs_cancel");
      case "acs_cancel_detail":
        // Order detail cancelled on Access Point
        return this.$t("shops.order_history.actions.acs_cancel_detail");
      case "email_confirm_sended":
        // Confirmation email sended
        return this.$t("shops.order_history.actions.email_confirm_sended");
      case "email_confirm_error":
        // An error ocurred when trying to send the confirmation email
        return this.$t("shops.order_history.actions.email_confirm_error");
      default:
        return value;
    }
  }
};
