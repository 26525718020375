<template>
  <main class="order-details">
    <data-table
      :data="tableData"
      :options="tableOptions"
    />
  </main>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";

export default {
  name: "order-details",

  props: {
    value: { type: Array, required: true },
    currency: String
  },

  components: {
    DataTable
  },

  data() {
    return {
      detailsInfo: []
    }
  },

  computed: {
    tableData() {
      this.loadDetailsAndExtras()
      return this.detailsInfo.map(this.orderDetailsToTableData)
    },

    tableOptions() {
      return {
        actions: {
          create: false,
          update: false,
          delete: false,
          cancelButton: true
        },
        filters: false,
        pagination: false
      }
    }
  },

  methods: {
    loadDetailsAndExtras(){
      this.detailsInfo = [];
      this.value.forEach(detail => {
        this.detailsInfo.push(detail)
        if(detail.extras)
          detail.extras.forEach(extra => this.detailsInfo.push(extra))
      })
    },
    orderDetailsToTableData(orderDetail) {
      return {
        id: orderDetail.id,
        customer_name: orderDetail.firstname || "---",
        customer_lastname: orderDetail.lastname || "---",
        reference: orderDetail.reference || "---",
        external_id: orderDetail.external_id || "",
        confirmation_code: orderDetail.confirmation_code || "---",
        product: orderDetail.product_name || "---",
        keycard: orderDetail.keycard || "---",
        unit_price: this.$options.filters.money(orderDetail.unit_price_tax, this.currency),
        quantity: orderDetail.quantity,
        total: this.$options.filters.money(orderDetail.total_price_tax, this.currency),
        status: orderDetail.status ? orderDetail.status.name : "---",
        date_of_use: orderDetail.date || null,
      }
    },
  }
}
</script>

<style>

</style>
