var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c(
        "main",
        { attrs: { id: "order-info" } },
        [
          _c(
            "vx-card",
            { staticClass: "mb-base no-shadow" },
            [
              _c("vx-card", { staticClass: "mb-6 m-0 p-0 no-shadow" }, [
                _c(
                  "div",
                  { staticClass: "vx-row" },
                  [
                    _c("div", { staticClass: "vx-col w-1/2" }, [
                      _c(
                        "div",
                        { staticClass: "order-infobo" },
                        [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.$t("shops.order_info.title")))
                          ]),
                          _c("order-infobox", {
                            model: {
                              value: _vm.order,
                              callback: function($$v) {
                                _vm.order = $$v
                              },
                              expression: "order"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.orderHistory.length > 0
                      ? [
                          _c("div", { staticClass: "vx-col w-1/2" }, [
                            _c(
                              "div",
                              { staticClass: "order-history" },
                              [
                                _c("h2", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("shops.order_info.history.title")
                                    )
                                  )
                                ]),
                                _c("order-history", {
                                  model: {
                                    value: _vm.orderHistory,
                                    callback: function($$v) {
                                      _vm.orderHistory = $$v
                                    },
                                    expression: "orderHistory"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "vx-row ml-2 mb-base" },
                  [
                    _vm.accessPoint
                      ? _c(
                          "div",
                          { staticClass: "order-relaunch w-full clear-both" },
                          [
                            _c("h2", [
                              _vm._v(
                                _vm._s(_vm.$t("shops.order_relaunch.title"))
                              )
                            ]),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-left mr-1 mt-1",
                                attrs: {
                                  icon: "sync",
                                  size: "large",
                                  type: "border",
                                  title: _vm.$t("Sync")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openPopup("sync")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("shops.order_relaunch.sync_order")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-left mr-1 mt-1",
                                attrs: {
                                  icon: "cancel",
                                  size: "large",
                                  type: "border",
                                  title: _vm.$t("Cancel")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openPopup("cancel")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("shops.order_relaunch.cancel_order")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-left mt-1",
                                attrs: {
                                  icon: "email",
                                  size: "large",
                                  type: "border",
                                  title: _vm.$t("Resend")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openPopup("email")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("shops.resend_email.resend"))
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "vs-popup",
                      {
                        attrs: {
                          classContent: "popup-example",
                          title: "",
                          active: _vm.popupEmail
                        },
                        on: {
                          "update:active": function($event) {
                            _vm.popupEmail = $event
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "mb-5" }, [
                          _vm._v(_vm._s(_vm.$t("shops.resend_email.message")))
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right",
                            attrs: { size: "large" },
                            on: {
                              click: function($event) {
                                _vm.popupEmail = false
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right mr-1",
                            attrs: { size: "large", type: "border" },
                            on: {
                              click: function($event) {
                                return _vm.sendEmail(_vm.order)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Accept")) + " ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "vs-popup",
                      {
                        attrs: {
                          classContent: "popup-example",
                          title: "",
                          active: _vm.popupCancel
                        },
                        on: {
                          "update:active": function($event) {
                            _vm.popupCancel = $event
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "mb-5" }, [
                          _vm._v(_vm._s(_vm.$t("shops.cancel_order.message")))
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right",
                            attrs: { size: "large" },
                            on: {
                              click: function($event) {
                                _vm.popupCancel = false
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right mr-1",
                            attrs: { size: "large", type: "border" },
                            on: {
                              click: function($event) {
                                return _vm.onCancelClicked(_vm.order)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Accept")) + " ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "vs-popup",
                      {
                        attrs: {
                          classContent: "popup-example",
                          title: "",
                          active: _vm.popupSync
                        },
                        on: {
                          "update:active": function($event) {
                            _vm.popupSync = $event
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "mb-5" }, [
                          _vm._v(_vm._s(_vm.$t("shops.sync_order.message")))
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right",
                            attrs: { size: "large" },
                            on: {
                              click: function($event) {
                                _vm.popupSync = false
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "float-right mr-1",
                            attrs: { size: "large", type: "border" },
                            on: {
                              click: function($event) {
                                return _vm.onSyncClicked(_vm.order)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Accept")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.orderDetails.length > 0
                ? [
                    _c(
                      "vx-card",
                      { staticClass: "order-details mb-6  no-shadow" },
                      [
                        _c("h2", [
                          _vm._v(
                            _vm._s(_vm.$t("shops.order_info.details.title"))
                          )
                        ]),
                        _c("order-details", {
                          attrs: { currency: _vm.currency },
                          model: {
                            value: _vm.orderDetails,
                            callback: function($$v) {
                              _vm.orderDetails = $$v
                            },
                            expression: "orderDetails"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.orderActions.length > 0
                ? [
                    _c(
                      "vx-card",
                      { staticClass: "order-actions no-shadow" },
                      [
                        _c("h2", [
                          _vm._v(
                            _vm._s(_vm.$t("shops.order_info.actions.title"))
                          )
                        ]),
                        _c("order-actions", {
                          model: {
                            value: _vm.orderActions,
                            callback: function($$v) {
                              _vm.orderActions = $$v
                            },
                            expression: "orderActions"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }