<template>
  <main id="order-info" v-if="order">
    <vx-card class="mb-base no-shadow">
      <vx-card class="mb-6 m-0 p-0 no-shadow">
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <div class="order-infobo">
              <h2>{{ $t("shops.order_info.title") }}</h2>
              <order-infobox v-model="order" />
            </div>
          </div>

          <template v-if="orderHistory.length > 0">
            <div class="vx-col w-1/2">
              <div class="order-history">
                <h2>{{ $t("shops.order_info.history.title") }}</h2>
                <order-history v-model="orderHistory" />
              </div>
            </div>
          </template>
        </div>
        <div class="vx-row ml-2 mb-base">
          <div v-if="accessPoint" class="order-relaunch w-full clear-both">
            <h2>{{ $t("shops.order_relaunch.title") }}</h2>
            <vs-button
              @click="openPopup('sync')"
              class="float-left mr-1 mt-1"
              icon="sync"
              size="large"
              type="border"
              :title="$t('Sync')"
              >{{ $t("shops.order_relaunch.sync_order") }}</vs-button
            >
            <vs-button
              @click="openPopup('cancel')"
              class="float-left mr-1 mt-1"
              icon="cancel"
              size="large"
              type="border"
              :title="$t('Cancel')"
              >{{ $t("shops.order_relaunch.cancel_order") }}</vs-button
            >
            <vs-button
              @click="openPopup('email')"
              class="float-left mt-1"
              icon="email"
              size="large"
              type="border"
              :title="$t('Resend')"
              >{{ $t("shops.resend_email.resend") }}</vs-button
            >
          </div>
          <vs-popup classContent="popup-example" title="" :active.sync="popupEmail">
            <p class="mb-5">{{ $t("shops.resend_email.message") }}</p>
            <vs-button @click="popupEmail = false" class="float-right" size="large">
              {{ $t("Cancel") }}
            </vs-button>
            <vs-button @click="sendEmail(order)" class="float-right mr-1" size="large" type="border">
              {{ $t("Accept") }}
            </vs-button>
          </vs-popup>
          <vs-popup classContent="popup-example" title="" :active.sync="popupCancel">
            <p class="mb-5">{{ $t("shops.cancel_order.message") }}</p>
            <vs-button @click="popupCancel = false" class="float-right" size="large">
              {{ $t("Cancel") }}
            </vs-button>
            <vs-button @click="onCancelClicked(order)" class="float-right mr-1" size="large" type="border">
              {{ $t("Accept") }}
            </vs-button>
          </vs-popup>
          <vs-popup classContent="popup-example" title="" :active.sync="popupSync">
            <p class="mb-5">{{ $t("shops.sync_order.message") }}</p>
            <vs-button @click="popupSync = false" class="float-right" size="large">
              {{ $t("Cancel") }}
            </vs-button>
            <vs-button @click="onSyncClicked(order)" class="float-right mr-1" size="large" type="border">
              {{ $t("Accept") }}
            </vs-button>
          </vs-popup>
        </div>
      </vx-card>

      <template v-if="orderDetails.length > 0">
        <vx-card class="order-details mb-6  no-shadow">
          <h2>{{ $t("shops.order_info.details.title") }}</h2>
          <order-details v-model="orderDetails" :currency="currency" />
        </vx-card>
      </template>

      <template v-if="orderActions.length > 0">
        <vx-card class="order-actions no-shadow">
          <h2>{{ $t("shops.order_info.actions.title") }}</h2>
          <order-actions v-model="orderActions" />
        </vx-card>
      </template>
    </vx-card>
  </main>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import OrderInfobox from "@/modules/Shops/Components/OrderInfobox.vue";
import OrderActions from "@/modules/Shops/Components/OrderActions.vue";
import OrderHistory from "@/modules/Shops/Components/OrderHistory.vue";
import OrderDetails from "@/modules/Shops/Components/OrderDetails.vue";
import notifications from "@/modules/Shared/Mixins/notifications";
import { mapGetters, mapActions } from "vuex";
import { shopapi } from "@/axios";
import i18n from "@/i18n";

const apiVersion = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  name: "order-info",

  mixins: [notifications],

  components: {
    DataTable,
    OrderInfobox,
    OrderActions,
    OrderHistory,
    OrderDetails
  },

  data() {
    return {
      accessPoint: false,
      popupEmail: false,
      popupCancel: false,
      popupSync: false
    };
  },

  computed: {
    ...mapGetters("shops/orders", {
      getOrderById: "getById",
      orderIndexById: "getIndexById",
      getOrderElement: "getElement"
    }),

    ...mapGetters("shops/resorts", {
      resortConfig: "resortConfig"
    }),

    ...mapGetters(['AppActiveUser']),

    order() {
      return this.getOrderElement;
    },

    orderHistory() {
      return this.order.history;
    },

    orderDetails() {
      return this.order.details;
    },

    orderActions() {
      return this.order.history;
    },

    currency() {
      return this.order.currency;
    }
  },

  methods: {
    ...mapActions("shops/resorts", {
      loadResortConfig: "loadResortConfig"
    }),
    ...mapActions("shops/orders", {
      loadOrderById: "loadById",
      setOrder: "setElement"
    }),
    async onSyncClicked(data) {
      this.$vs.loading();
      let response;
      try {
        response = await shopapi.post(`/api/${apiVersion}/resorts/${this.$route.params.uuid}/orders/${data.id}/sync`, {username: this.AppActiveUser.username}).catch(error => {
          this.notifyError(i18n.t("shops.error"), error.response.data.error.message);
        });

        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.the_order_was_successfully_synched"));
          const index = this.orderIndexById(data.id);

          if (index !== -1) {
            this.$store.commit("shops/orders/SET_BY_INDEX", { index, resource: response.data }, { root: true });
            await this.setOrder(response.data);
          } else {
            console.warn(i18n.t("shops.failed_to_update_order_in_store"), index);
          }
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.there_was_an_error_while_trying_to_sync_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.popupSync = false;
        this.$vs.loading.close();
      }
    },

    openPopup(button) {
      if (button === "email") this.popupEmail = true;
      else if (button === "cancel") this.popupCancel = true;
      else if (button === "sync") this.popupSync = true;
    },

    async sendEmail(data) {
      this.$vs.loading();
      try {
        const response = await shopapi.post(`/api/${apiVersion}/resorts/${this.$route.params.uuid}/orders/${data.id}/send_confirmation_email`, {username: this.AppActiveUser.username});
        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.email_sent_correctly"));
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.errors.there_was_an_error_while_trying_to_send_email_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
        this.notifyError(
          i18n.t("shops.error"),
          i18n.t("shops.errors.there_was_an_error_while_trying_to_send_email_please_try_again_later")
        );
      } finally {
        this.popupEmail = false;
        this.$vs.loading.close();
      }
    },

    async onCancelClicked(data) {
      this.$vs.loading();
      try {
        const response = await shopapi.post(`/api/${apiVersion}/resorts/${this.$route.params.uuid}/orders/${data.id}/cancel`, {username: this.AppActiveUser.username});

        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.the_order_was_successfuly_canceled"));
          const index = this.orderIndexById(data.id);

          if (index !== -1) {
            this.$store.commit("shops/orders/SET_BY_INDEX", { index, resource: response.data }, { root: true });
            await this.setOrder(response.data);
          } else {
            console.warn(i18n.t("shops.failed_to_update_order_in_store"), index);
          }
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.errors.there_was_an_error_while_trying_to_cancel_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.popupCancel = false;
        this.$vs.loading.close();
      }
    }
  },

  async created() {
    this.$vs.loading();
    await this.loadOrderById({id: this.$route.params["order_id"]});
    await this.loadResortConfig(this.$route.params.uuid);
    let config = await this.resortConfig.system;
    if (config !== "none" && config !== undefined && config !== null) {
      this.accessPoint = true;
    }
    this.$vs.loading.close();
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 1em;
}

.order-relaunch {
  float: left;
  margin-top: 30px;
}

.vs-button-filled {
  border: 1px solid red;
}
</style>
