var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "order-infobox" }, [
    _c("table", [
      _c(
        "tr",
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-base",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.editMode ? _vm.$t("shops.cancel") : _vm.$t("shops.edit")
                )
              )
            ]
          )
        ],
        1
      ),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.id")))]),
        _c("td", [_vm._v(_vm._s(_vm.value.id))])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.reference")))
        ]),
        _c("td", [_vm._v(_vm._s(_vm.value.reference))])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.firstname")))
        ]),
        !_vm.editMode
          ? _c("td", [_vm._v(_vm._s(_vm.value.firstname))])
          : _c(
              "td",
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "firstname" },
                  model: {
                    value: _vm.clientInfo.firstname,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "firstname", $$v)
                    },
                    expression: "clientInfo.firstname"
                  }
                })
              ],
              1
            )
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.lastname")))]),
        !_vm.editMode
          ? _c("td", [_vm._v(_vm._s(_vm.value.lastname))])
          : _c(
              "td",
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "lastname" },
                  model: {
                    value: _vm.clientInfo.lastname,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "lastname", $$v)
                    },
                    expression: "clientInfo.lastname"
                  }
                })
              ],
              1
            )
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.email")))]),
        !_vm.editMode
          ? _c("td", [_vm._v(_vm._s(_vm.value.email))])
          : _c(
              "td",
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "email" },
                  model: {
                    value: _vm.clientInfo.email,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "email", $$v)
                    },
                    expression: "clientInfo.email"
                  }
                })
              ],
              1
            )
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.phone")))]),
        !_vm.editMode
          ? _c("td", [_vm._v(_vm._s(_vm.value.phone))])
          : _c(
              "td",
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "phone" },
                  model: {
                    value: _vm.clientInfo.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "phone", $$v)
                    },
                    expression: "clientInfo.phone"
                  }
                })
              ],
              1
            )
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.subtotal")))]),
        _c("td", [
          _vm._v(
            _vm._s(_vm._f("money")(_vm.value.total_price, _vm.value.currency))
          )
        ])
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.taxes")))]),
        _c("td", [
          _vm._v(
            _vm._s(_vm._f("money")(_vm.value.total_tax, _vm.value.currency))
          )
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(
            _vm._s(_vm.$t("shops.order_info.infobox.total_without_discounts"))
          )
        ]),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm._f("money")(_vm.value.total_products_tax, _vm.value.currency)
            )
          )
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(
            _vm._s(_vm.$t("shops.order_info.infobox.total_with_discounts"))
          )
        ]),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm._f("money")(_vm.value.total_price_tax, _vm.value.currency)
            )
          )
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.promotion")))
        ]),
        _c("td", [
          _vm._v(_vm._s(_vm.value.promotion ? _vm.value.promotion.name : "---"))
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.date_purchase")))
        ]),
        _c("td", [_vm._v(_vm._s(_vm._f("datetime")(_vm.value.created_at)))])
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.payment")))]),
        _c("td", [
          _vm._v(_vm._s(_vm.value.payment ? _vm.value.payment.method : "---"))
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.transaction")))
        ]),
        _c("td", [
          _vm._v(
            _vm._s(
              _vm.value.payment ? _vm.value.payment.transaction_code : "---"
            )
          )
        ])
      ]),
      _c("tr", [
        _c("th", [
          _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.external_id")))
        ]),
        _c("td", [_vm._v(_vm._s(_vm.value.external_id || "---"))])
      ]),
      _c("tr", [
        _c("th", [_vm._v(_vm._s(_vm.$t("shops.order_info.infobox.status")))]),
        !_vm.editMode
          ? _c("td", [_vm._v(_vm._s(_vm.value.status.name))])
          : _c(
              "td",
              [
                _c("v-select", {
                  staticClass: "w-full",
                  attrs: {
                    value: _vm.statusSelected,
                    options: _vm.status.map(function(status) {
                      return {
                        label: status.label,
                        value: status.label,
                        id: status.value
                      }
                    }),
                    name: "status",
                    placeholder: _vm.$t("shops.select_a_status")
                  },
                  on: {
                    input: function(status) {
                      return _vm.selectStatus(status)
                    }
                  }
                })
              ],
              1
            )
      ]),
      _c(
        "tr",
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-base",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.onSave()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }