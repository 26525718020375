<template>
  <main class="order-actions">
    <data-table
      :data="tableData"
      :options="tableOptions"
    />
  </main>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import orderCases from "@/modules/Shops/Helpers/orderCases.js"

export default {
  name: "order-actions",

  props: {
    value: { type: Array, required: true }
  },

  components: {
    DataTable
  },

  data() {
    return {}
  },

  computed: {
    tableData() {
      return this.value.map(this.orderActionToTableData);
    },

    tableOptions() {
      return {
        actions: false,
        filters: false,
        pagination: false
      }
    }
  },
  methods: {
    orderActionToTableData(orderAction) {

      if (orderAction.info) orderAction.info = orderCases.getStatusDetailName(orderAction.info);
      if (orderAction.action) orderAction.action = orderCases.getActionName(orderAction.action);

      return {
          action: orderAction.action,
          info: orderAction.info || "-",
          order_detail_id: orderAction.order_detail_id || "-",
          username: orderAction.username || "-",
          date: orderAction.created_at,
      };
    }
  }
}
</script>

<style>

</style>
